<template>
  <!-- Set component styling -->
  <component :is="`style`">
    <!-- Api driven styles -->
    .event {
    background: {{background}};
    }
    .tab {
    color: {{tabFontColor}} !important;
    background-color: {{tabBackground}};
    font-family: {{primaryFont}} !important;
    font-size: {{fontSize}} !important;
    }
    .tab:hover {
    color: {{tabActiveFontColor}} !important;
    background-color: {{tabActiveBackground}};
    }
    .selected {
    color: {{tabActiveFontColor}} !important;
    background-color: {{tabActiveBackground}};
    }
    .tabs {
    border-bottom: 3px solid {{tabActiveBackground}};
    }
    .button {
    font-family: {{primaryFont}} !important;
    }
    .is-primary {
    color: {{buttonTextColor}} !important;
    background-color: {{buttonBackground}} !important;
    }
    .is-primary:hover {
    color: {{buttonActiveFontColor}} !important;
    background-color: {{buttonActiveBackgroundColor}} !important;
    }
    .comment-content {
    font-family: {{secondaryFont}} !important;
    }
    .qaTitle {
    color: {{tabBackground}};
    font-weight: bold;
    line-height: 18px;
    }
    emoji-picker {
    width: 100%;
    max-width: none;
    height: 290px;
    --num-columns: 7;
    --category-emoji-size: 1.125rem;
    }
    .counter {
    color: {{tabBackground}};
    }
    .border-top {
    border-top: 1px solid {{tabBackground}} !important;
    }
    .border-bottom {
    border-bottom: 1px solid {{tabBackground}} !important;
    }
  </component>
  <main class="event-frame" v-if="event?.status === 'active' || showEventPreview && activeSessions">
    <div class="event">
      <div class="tabs" v-if="activeSessions">
        <ul>
          <li class="tab" v-for="(session) of sessions" v-bind:key="session" v-on:click="getSession(session); resetTabCount(session,viewingThisSession?.id);" v-bind:class="{selected: viewingThisSession?.id === session?.id}">
            <p v-if="session?.id !== 2">{{session?.name}}</p>
            <p v-if="session?.id === 2">{{session?.tabName}}</p>
            <!-- Notifications -->
            <p class="counter" v-bind:class="{'hidden': session?.id !== 2 || viewingThisSession?.id === 2  || missedMessageContentActiveAmount === missedMessageCountContent || missedMessageCountContentInit === false}">!</p>
            <!-- Counters -->
            <p class="counter" v-bind:class="{'hidden': session?.id !== 0 || viewingThisSession?.id === 0  || missedMessageCountChat === 0 || missedMessageCountInit === false}">{{missedMessageCountChat}}</p>
            <div v-for="c of counters" v-bind:key="c" class="hidden">
              <p class="counter" v-bind:class="{'hidden': c.sessionID === session?.sessionID && c.counter === 0}">{{c?.counter}}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="session" v-if="sessionSelected" v-bind:style="{display: sessionSelected === true ? 'block' : 'hidden'}">
        <div style="width: 100%; height: 100%;" v-if="showSessionInformation">
          <!-- Chat / QA section -->
          <div class="alert" v-if="viewingThisSession.id === 1 && event?.tagline">
            <p v-if="event?.tagline">{{event?.tagline}}</p>
            <p v-if="event?.tagline2">{{event?.tagline2}}</p>
          </div>
          <div class="session-contents is-flex is-justify-content-flex-start" v-bind:class="{'is-flex-direction-column': viewingThisSession?.id === 0, 'is-flex-direction-column-reverse': viewingThisSession?.id === 1}" v-if="viewingThisSession?.id === 0 || viewingThisSession?.id === 1">
            <div class="comments-section" v-bind:class="{'smaller': viewingThisSession?.id === 1}">
              <!-- Chat -->
              <div v-if="viewingThisSession.id === 0">
                <!-- Pinned section -->
                <div class="pinned-message" v-if="hasPinnedMessage && viewingThisSession?.id === 0">
                  <div v-if="!isPinnedURL" style="width: 100%; display: flex; flex-direction: row">
                    <span class="icon is-small"><font-awesome-icon icon="thumbtack"></font-awesome-icon></span> <p class="ml-2" style="font-size: 1rem !important;"><b style="font-size: 1.1rem  !important;">{{pinnedMessage}}</b></p>
                  </div>
                  <div v-else>
                    <p class="ml-2" style="font-size: 1rem !important;"><font-awesome-icon icon="link" class="mr-2"></font-awesome-icon><a :href="pinnedMessage" target="_blank">{{pinnedMessage}}</a></p>
                  </div>
                </div>
                <p>LATEST POSTS:</p>
                <p v-if="viewingThisSession?.comments.length <= 0">Waiting for data...</p>
                <ul id="out" v-bind:class="{'pinnedStyling': hasPinnedMessage}" style="overflow:auto; max-height: 68vh; padding-bottom: 10px;" v-if="viewingThisSession.comments.length >= 1" @scroll="handleScroll">
                  <li v-for="comment of viewingThisSession.comments" :key="comment">
                    <div class="comment" v-if="comment?.status === 'approved'">
                      <p style="font-size: .9rem !important;" v-if="comment?.name">{{comment?.name}}: <b style="font-size: 1rem  !important;">{{comment?.comment}}</b></p>
                      <p style="font-size: .9rem  !important;" v-if="!comment?.name">{{comment?.name}} <b style="font-size: 1rem  !important">{{comment?.comment}}</b></p>
                    </div>
                  </li>
                </ul>
              </div>
              <!-- QA -->
              <div v-if="viewingThisSession.id === 1">
                <p v-if="viewingThisSession?.questions.length <= 0">No recently submitted questions.</p>
                <div class="comments-section mb-3 mt-2" v-else>
                  <p class="qaTitle">Your Recently Submitted Questions:</p>
                  <ul>
                    <li v-for="question of viewingThisSession?.questions.slice().reverse()" v-bind:key="question">
                      <div class="comment" v-if="question?.uid === uuid">
                        <div class="comment-contents" style="width: 100%">
                          <p class="comment-content" v-bind:style="{color: event.secondaryFontColor, background: event.secondaryColor}"><b>{{question?.comment}}</b></p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="emoji-section" v-if="selectingEmoji">
              <VuemojiPicker @emojiClick="handleEmojiClick" :is-dark="false"/>
            </div>
            <div class="required-fields" v-bind:class="{'border-top': viewingThisSession?.id === 0, 'border-bottom': viewingThisSession?.id === 1}">
              <form @keydown.enter.prevent="submitData($event)" @submit.prevent="submitData($event)">
                <div class="field is-horizontal" v-if="!viewingThisSession?.protected">
                  <div class="field-body">
                    <div class="field">
                      <label class="label" for="comment-name">Name: <i style="font-size: .75rem !important; font-style: italic; color:  #757575;" v-if="viewingThisSession?.anonymous">(optional)</i></label>
                      <div class="control">
                        <input tabindex="1" class="input" id="comment-name" type="text" placeholder="" v-model="sessionComment.name" v-bind:class="{'is-danger': submittingError === true && sessionComment.nameError === true}"/>
                        <p class="help is-danger" v-if="submittingError === true && sessionComment.nameError === true">
                          Please enter an name
                        </p>
                      </div>
                    </div>
                    <div class="field">
                      <label class="label" for="comment-email">Email Address: <i style="font-size: .75rem !important; font-style: italic; color: #757575;" v-if="viewingThisSession?.anonymous">(optional)</i></label>
                      <div class="control">
                        <input tabindex="1" class="input" id="comment-email" type="email" placeholder=""  v-model="sessionComment.email" v-bind:class="{'is-danger': submittingError === true && sessionComment.emailError === true}"/>
                        <p class="help is-danger" v-if="submittingError === true && sessionComment.emailError === true">
                          Please enter an email
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <label class="label" v-if="viewingThisSession.id !== 1">Message</label>
                  <label class="label" v-if="viewingThisSession.id === 1">Question:</label>
                  <div class="is-flex is-flex-direction-row is-align-content-flex-start is-justify-content-flex-start">
                    <div class="control" style="width: 95%;">
                      <textarea class="textarea" placeholder="Shift + enter for new line" v-model="sessionComment.comment" v-bind:class="{'is-danger': submittingError === true && sessionComment.commentError === true}"></textarea>
                    </div>
                    <div class="button is-ghost" style="margin-left: 10px">
                    <span class="icon is-small" v-on:click="selectingEmoji = !selectingEmoji">
                      <font-awesome-icon icon="smile" v-if="!selectingEmoji"></font-awesome-icon>
                      <font-awesome-icon icon="times" v-if="selectingEmoji"></font-awesome-icon>
                    </span>
                    </div>
                  </div>
                  <div class="bottom-section is-flex is-flex-direction-row is-justify-content-flex-end">
                    <div class="field">
                      <p class="control" v-if="viewingThisSession?.protected">
                        <button class="button is-primary is-small" type="submit" :disabled="sessionComment.comment.trim() === '' || preventionState === true"  v-bind:class="{'is-loading': submitting === true || preventionState === true}">
                          SEND  <font-awesome-icon class="ml-2" icon="long-arrow-alt-right"></font-awesome-icon>
                        </button>
                      </p>
                      <p class="control" v-if="viewingThisSession?.anonymous">
                        <button class="button is-primary is-small" type="submit"  :disabled="sessionComment.comment.trim() === '' || preventionState === true"  v-bind:class="{'is-loading': submitting === true || preventionState === true}">
                          SEND  <font-awesome-icon class="ml-2" icon="long-arrow-alt-right"></font-awesome-icon>
                        </button>
                      </p>
                      <p class="comment" v-if="viewingThisSession?.register">
                        <button class="button is-primary is-small" type="submit" :disabled="sessionComment.name === '' || sessionComment.email === '' || sessionComment.comment.trim() === '' || preventionState === true"  v-bind:class="{'is-loading': submitting === true || preventionState === true}">
                          SEND  <font-awesome-icon class="ml-2" icon="long-arrow-alt-right"></font-awesome-icon>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- Dyanamic contents -->
          <div class="session-contents" v-else>
            <div class="comments-section" v-if="viewingThisSession?.contents">
              <ul>
                <li v-for="content of viewingThisSession?.contents" :key="content">
                  <div v-if="content?.status === 'approved'" style="margin-bottom: 13px;">
                    <div v-if="content?.contentType === 'Youtube' || content?.contentType === 'Vimeo'">
                      <figure class="image is-16by9">
                        <iframe class="has-ratio" width="640" height="360" :src="content?.url" frameborder="0" allowfullscreen></iframe>
                      </figure>
                    </div>
                    <div v-if="content?.contentType === 'Image'">
                      <img style='height: 100%; width: 100%; object-fit: cover' :src="content?.url">
                    </div>
                    <div v-if="content?.contentType === 'Website' && content?.fileDownload === false">
                      <iframe class="has-ratio" width="100%" height="700" :src="content?.url" frameborder="0" allowfullscreen></iframe>
                    </div>
                    <div v-if="content?.contentType === 'Website' && content?.fileDownload === true" class="fileDownload">
                      <a :href="content?.url" target="_blank" download><font-awesome-icon icon="download" class="mr-3"></font-awesome-icon>  {{content?.name || content?.url}}</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 100%;" v-else>
          <div class="session-contents-empty is-flex is-justify-content-flex-start">
            <p>Please login to participate in this session</p>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- Event is active and no sessions set -->
  <div class="modal is-flex" v-if="event?.status === 'active' && event?.sessions.length === 0">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Event is coming soon.</p>
          </div>
        </div>
        <div class="modal-actions" >
          <p>Currently no sessions, set for this event. Please check back shortly.</p>
        </div>
      </section>
    </div>
  </div>
  <!-- Event is active and has sessions, but no active ones -->
  <div class="modal is-flex" v-if="!activeSessions">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Event is coming soon.</p>
          </div>
        </div>
        <div class="modal-actions" >
          <p>Currently no sessions, for this event. Please check back shortly.</p>
        </div>
      </section>
    </div>
  </div>
  <!-- Event is inactive -->
  <div class="modal is-flex" v-if="!showEventPreview && event?.status === 'inactive'">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Event is coming soon.</p>
          </div>
        </div>
        <div class="modal-actions" >
          <p>Please check back when the event starts</p>
        </div>
      </section>
    </div>
  </div>
  <!-- Event is completed -->
  <div class="modal is-flex" v-if="!showEventPreview && event?.status === 'completed'">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Event is over.</p>
          </div>
        </div>
        <div class="modal-actions" >
          <p>Thank you for participating.</p>
        </div>
      </section>
    </div>
  </div>
  <!-- Event is archived -->
  <div class="modal is-flex" v-if="!showEventPreview && event?.status === 'archived'">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Event is over.</p>
          </div>
        </div>
        <div class="modal-actions" >
          <p>Thank you for participating.</p>
        </div>
      </section>
    </div>
  </div>
  <!-- Event is deleted -->
  <div class="modal is-flex" v-if="!showEventPreview && event?.status === 'deleted'">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">Event is over.</p>
          </div>
        </div>
        <div class="modal-actions" >
          <p>Thank you for participating.</p>
        </div>
      </section>
    </div>
  </div>
  <!-- No Event -->
  <div class="modal is-flex" v-if="noEvent">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="upper">
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <p class="title">No Event</p>
          </div>
        </div>
        <div class="modal-actions" >
          <p>Currently there is no event</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/database';

// Third party imports
import { VuemojiPicker } from 'vuemoji-picker';

export default {
  name: 'event',
  components: {
    VuemojiPicker,
  },
  data() {
    return {
      id: '',
      uuid: '',
      // No event
      noEvent: false,
      // Event preview
      showEventPreview: false,
      // colors
      background: '',
      tabBackground: '',
      tabFontColor: '',
      tabActiveBackground: '',
      tabActiveFontColor: '',
      buttonBackground: '',
      buttonTextColor: '',
      buttonActiveBackgroundColor: '',
      buttonActiveFontColor: '',
      commentFontColor: '',
      commentBackgroundColor: '',
      // Fonts
      fontSize: '',
      fontURL: '',
      primaryFont: '',
      secondaryFont: '',
      // Event
      event: {},
      // Sessions
      activeSessions: false,
      sessions: [],
      // Counters
      selectedKey: '',
      sessionsToWatch: [],
      counters: [],
      updatedKey: '',
      missedMessageCountChat: 0,
      missedMessageCountInit: false,
      missedMessageCountContent: 0,
      missedMessageCountContentInit: false,
      missedMessageContentActiveAmount: 0,
      missedMessageActiveTab: 0,
      // Selected session
      sessionSelected: false,
      viewingThisSession: {},
      viewingThisSessionKey: '',
      // Session flags
      showSessionInformation: false,
      protected: false,
      protectedButton: false,
      anonymous: false,
      register: false,
      notLoggedIn: false,
      // Pinned message
      hasPinnedMessage: false,
      pinnedMessage: {},
      isPinnedURL: false,
      // Session comments
      sessionComment: {
        key: '',
        uid: '',
        name: '',
        email: '',
        comment: '',
        status: '',
        date: '',
        isNew: true,
        nameError: false,
        emailError: false,
        commentError: false,
      },
      // Emoji
      selectingEmoji: false,
      // Fields
      showFields: false,
      showUserInfo: false,
      // Prevention states
      preventionState: false,
      submittingError: false,
      // submitting data
      typeToSubmit: '',
      submitting: false,
      // Scrolling
      scrollToBottom: true,
      initialScrollHeight: 0,
    };
  },
  created() {
    // Grab the event id from the url
    // TODO: Note this may change if you update the url
    // eslint-disable-next-line prefer-destructuring
    this.id = window.location.pathname.split('/')[2];
    if (this.id !== undefined && this.id !== null) {
      this.getEvent();
      this.genUUID();
      this.getFonts();
    }
  },
  mounted() {
    const vm = this;
    setTimeout(() => {
      vm.missedMessageCountChat = 0;
      vm.missedMessageCountInit = true;
      vm.missedMessageCountContent = vm.missedMessageContentActiveAmount;
      vm.missedMessageCountContentInit = true;
    }, 1500);
  },
  methods: {
    getEvent() {
      const eventRef = firebase.database().ref(`events/${this.id}`);
      eventRef.on('value', (snapshot) => {
        if (snapshot.exists()) {
          const eventObj = snapshot.val();
          this.setMetaData(eventObj.favicon, eventObj.name);
          this.setEventStyling(eventObj);
          if (eventObj?.status === 'inactive' || eventObj?.status === 'completed') {
            // Run a check for the admin for the ability to preview the event event
            if (this.$route.query.firstname && this.$route.query.emailaddress) {
              // Let's check for admin
              if (this.$route.query.firstname === 'Admin' && this.$route.query.emailaddress === 'Admin@go.huddlearea.com') {
                console.info(`Event is ${eventObj?.status}, and admin is previewing it.`);
                this.showEventPreview = true;
                if (eventObj?.sessions) {
                  eventObj.sessions = Object.values(eventObj?.sessions);
                  this.getSessions(Object.values(eventObj?.sessions));
                } else {
                  eventObj.sessions = [];
                }
              } else {
                console.info(`Event is ${eventObj?.status}, and no admin is previewing it.`);
                this.showEventPreview = false;
              }
            } else {
              console.info(`Event is ${eventObj?.status}, and general public is previewing it.`);
              this.showEventPreview = false;
            }
          }
          // Get sessions only when event is active -- no need to waste resources
          if (eventObj?.status === 'active') {
            // Check if we have sessions
            if (eventObj?.sessions) {
              eventObj.sessions = Object.values(eventObj?.sessions);
              this.getSessions(Object.values(eventObj?.sessions));
            } else {
              eventObj.sessions = [];
            }
          }
          this.event = eventObj;
        } else {
          console.info('No event per this id');
          this.noEvent = true;
        }
      });
    },
    // Tab Counter
    resetTabCount(session, viewingThisSessionID) {
      const vm = this;
      if (session?.id === 0 || vm.missedMessageActiveTab === 0) {
        vm.missedMessageCountInit = true;
        vm.missedMessageCountChat = 0;
      }
      if (session?.id === 2) {
        vm.missedMessageCountContentInit = true;
        vm.missedMessageCountContent = vm.missedMessageContentActiveAmount;
      }
      vm.missedMessageActiveTab = viewingThisSessionID;
    },
    // Getting sessions and setting defualt session
    getSessions(sessions) {
      const vm = this;
      try {
        /**
         * id 0: Live chat
         * id 1: QA
         * id 2: Dynamic media
         */
        // Order by oderID
        if (sessions && sessions.length >= 2) {
          sessions.sort((a, b) => a.orderID - b.orderID);
        }
        sessions.forEach((session) => {
          const sessionRef = firebase.database().ref(`sessions/${session.sessionID}`);
          sessionRef.on('value', (snap) => {
            if (snap.exists()) {
              const sessionObj = snap.val();
              if (sessionObj.status === 'active') {
                // Check to see if we add this to the array or if it already exists in that array
                const search = this.sessions.find((element) => element.sessionID === session.sessionID);
                if (search !== undefined) {
                  // eslint-disable-next-line no-shadow
                  const indexSearch = this.sessions.findIndex(((obj) => obj.sessionID === session.sessionID));
                  this.sessions[indexSearch] = sessionObj;
                } else {
                  this.sessions.push(sessionObj);
                }
              } else {
                // eslint-disable-next-line no-unused-expressions
                // eslint-disable-next-line no-shadow
                this.sessions = this.sessions.filter((session) => session.sessionID !== sessionObj.sessionID);
              }
              if (this.sessions && this.sessions.length >= 1) {
                // eslint-disable-next-line no-shadow
                const activeArr = this.sessions.filter((session) => session.status === 'active');
                if (activeArr.length >= 1) {
                  this.activeSessions = true;
                } else {
                  this.activeSessions = false;
                }
              } else {
                this.activeSessions = false;
              }
            }
          });
        });
        sessions.forEach((session) => {
          if (session.type === 'chat') {
            const ref = firebase.database().ref(`sessions/${session.sessionID}`);
            const comments = ref.child('comments');
            comments.on('child_added', (dataSnapshot) => {
              const commentChanged = dataSnapshot.val();
              if (commentChanged.status === 'approved') {
                this.updateCounters(session.sessionID);
                vm.missedMessageCountChat += 1;
              }
            });
            comments.on('child_changed', (dataSnapshot) => {
              const commentChanged = dataSnapshot.val();
              if (commentChanged.status === 'approved') {
                this.updateCounters(session.sessionID);
              }
            });
            ref.on('value', (snap) => {
              const obj = snap.val();
              let tempCount = 0;
              // // eslint-disable-next-line no-shadow
              if (obj.comments) {
                const contents = Object.entries(obj.comments);
                contents.forEach((c) => {
                  if (c[1].status === 'approved') {
                    // eslint-disable-next-line no-plusplus
                    tempCount++;
                  }
                });
                if (tempCount === 0) {
                  this.resetCounter(session.sessionID);
                }
                this.scrolling();
              }
            });
          }
          if (session.type === 'content') {
            const ref = firebase.database().ref(`sessions/${session.sessionID}`);
            const comments = ref.child('contents');
            comments.on('child_added', (dataSnapshot) => {
              const commentChanged = dataSnapshot.val();
              if (commentChanged.status === 'approved') {
                this.updateCounters(session.sessionID);
              }
            });
            comments.on('child_changed', (dataSnapshot) => {
              const commentChanged = dataSnapshot.val();
              if (commentChanged.status === 'approved') {
                this.updateCounters(session.sessionID);
              }
            });
            ref.on('value', (snap) => {
              const obj = snap.val();
              let tempCount = 0;
              // eslint-disable-next-line no-shadow
              const contents = Object.entries(obj.contents);
              vm.missedMessageContentActiveAmount = 0;
              contents.forEach((c) => {
                if (c[1].status === 'approved') {
                  vm.missedMessageContentActiveAmount += 1;
                  // eslint-disable-next-line no-plusplus
                  tempCount++;
                }
              });
              if (tempCount === 0) {
                this.resetCounter(session.sessionID);
              }
            });
          }
        });
        this.createCounters();
        this.getSession(sessions[0]);
      } catch (e) {
        if (e) {
          console.error(`Problem getting sessions ${e}`);
        }
      }
    },
    createCounters() {
      console.log('Sessions: ', this.sessions);
      this.sessions.forEach((session) => {
        if (session.status === 'active') {
          this.counters.push({
            counter: 0,
            sessionID: session.sessionID,
          });
        }
      });
    },
    // Get a single session information
    getSession(session) {
      try {
        this.selectedKey = session.sessionID;
        const sessionRef = firebase.database().ref(`sessions/${session.sessionID}`);
        sessionRef.on('value', (snap) => {
          if (snap.exists()) {
            const obj = snap.val();
            if (obj.status === 'active' && this.selectedKey === obj.sessionID) {
              if (obj.id === 0) {
                if (obj.comments) {
                  obj.comments = Object.values(obj.comments);
                } else {
                  obj.comments = [];
                }
                obj.comments.sort((a, b) => new Date(a.date) - new Date(b.date));
                if (obj?.pinnedMessage !== '') {
                  this.isPinnedURL = false;
                  this.hasPinnedMessage = true;
                  this.pinnedMessage = obj?.pinnedMessage;
                  // Check if a url
                  setTimeout(() => {
                    try {
                      const urlCheck = Boolean(new URL(obj?.pinnedMessage));
                      this.isPinnedURL = urlCheck;
                    } catch (e) {
                      this.isPinnedURL = false;
                    }
                  }, 200);
                } else {
                  this.hasPinnedMessage = false;
                }
              }
              if (obj.id === 1) {
                if (obj.questions) {
                  obj.questions = Object.values(obj.questions);
                } else {
                  obj.questions = [];
                }
                // eslint-disable-next-line no-unused-expressions
                obj.questions?.forEach((question) => {
                  if (question?.status === 'pinned') {
                    this.hasPinnedMessage = true;
                    this.pinnedMessage = question;
                  }
                });
                obj.questions.sort((a, b) => new Date(a.date) - new Date(b.date));
                this.uuid = sessionStorage.getItem('uid');
              }
              if (obj.id === 2) {
                if (obj.contents) {
                  obj.contents = Object.values(obj.contents);
                } else {
                  obj.contents = [];
                }
              }
              // Protected checks
              if (obj.protected) {
                if (this.$route.query.firstname !== undefined) {
                  this.showSessionInformation = true;
                  if (this.$route.query.firstname === 'Admin' && this.$route.query.emailaddress === 'Admin@go.huddlearea.com') {
                    const email = this.$route.query.emailaddress;
                    const { firstname } = this.$route.query;
                    const { lastname } = this.$route.query;
                    this.sessionComment.name = `${firstname} ${lastname}`.replace(/\\/g, '');
                    this.sessionComment.email = email;
                  } else {
                    const email = atob(this.$route.query.emailaddress);
                    const firstname = atob(this.$route.query.firstname);
                    const lastname = atob(this.$route.query.lastname);
                    this.sessionComment.name = `${firstname} ${lastname}`.replace(/\\/g, '');
                    this.sessionComment.email = email;
                  }
                } else {
                  this.showSessionInformation = false;
                }
              }
              // Registered set
              if (obj.register) {
                this.showSessionInformation = true;
                // Check for data in the url
                if (this.$route.query.firstname !== undefined) {
                  if (this.$route.query.firstname === 'Admin' && this.$route.query.emailaddress === 'Admin@go.huddlearea.com') {
                    const email = this.$route.query.emailaddress;
                    const { firstname } = this.$route.query;
                    const { lastname } = this.$route.query;
                    this.sessionComment.name = `${firstname} ${lastname}`.replace(/\\/g, '');
                    this.sessionComment.email = email;
                  } else {
                    const email = atob(this.$route.query.emailaddress);
                    const firstname = atob(this.$route.query.firstname);
                    const lastname = atob(this.$route.query.lastname);
                    this.sessionComment.name = `${firstname} ${lastname}`.replace(/\\/g, '');
                    this.sessionComment.email = email;
                  }
                }
              }
              // Anonymous mode
              if (obj.anonymous) {
                this.showSessionInformation = true;
              }
              this.sessionSelected = true;
              this.viewingThisSession = obj;
              setTimeout(() => {
                // this.scrolling();
                this.resetCounter(obj.sessionID);
              }, 500);
            } else {
              console.info(`Session is not active, currently ${session.status}`);
            }
          }
        });
        // Let's set the initial height of the scrolling element
        setTimeout(() => {
          const out = document.getElementById('out');
          this.initialScrollHeight = out.scrollTop;
          this.scrolling();
        }, 100);
      } catch (e) {
        console.error(`Issue with single session: ${e}`);
      }
    },
    // Session data submissions
    handleEmojiClick(detail) {
      if (this.sessionComment.comment === '') {
        this.sessionComment.comment = detail.unicode.trim();
      } else {
        this.sessionComment.comment = `${this.sessionComment.comment} ${detail.unicode.trim()}`;
      }
      this.selectingEmoji = !this.selectingEmoji;
    },
    // Submitting comments
    async submitData(event) {
      // Shift + enter used
      if (event.keyCode === 13 && event.shiftKey) {
        this.sessionComment.comment = `${this.sessionComment.comment} \n`;
        this.submitting = false;
      } else {
        // eslint-disable-next-line no-lonely-if,no-mixed-operators
        if (this.sessionComment.comment === '' || this.sessionComment.comment === ' ' || this.sessionComment.comment.trim() === '' || this.preventionState === true) {
          console.info('No form values set, do nothing');
        } else {
          this.submitting = true;
          this.preventionState = true;
          if (this.viewingThisSession.id === 0) {
            this.typeToSubmit = 'comments';
          }
          if (this.viewingThisSession.id === 1) {
            this.typeToSubmit = 'questions';
          }
          // Set status to pending if moderation is flagged
          if (this.viewingThisSession?.moderation) {
            this.sessionComment.status = 'pending';
          } else {
            this.sessionComment.status = 'approved';
          }
          // Run logic on types set
          if (this.typeToSubmit !== '' && this.submitting === true) {
            // Generate a key for firebase to insert the comment in correctly.
            const uKey = firebase.database().ref(`sessions/${this.viewingThisSession.sessionID}/${this.typeToSubmit}`).push().key;
            this.sessionComment.key = uKey;
            this.sessionComment.uid = this.uuid;
            this.sessionComment.date = firebase.database.ServerValue.TIMESTAMP;
            // Run check for anon mode
            if (this.viewingThisSession.anonymous) {
              if (this.sessionComment.name === '') {
                this.sessionComment.name = 'Anonymous';
              }
              if (this.sessionComment.email === '') {
                this.sessionComment.email = 'noemail@noemail.com';
              }
            }
            // Run check for register mode
            if (this.viewingThisSession.register && this.sessionComment.name !== '' && this.sessionComment.email !== '' && this.sessionComment.comment !== '' && this.sessionComment.comment !== ' ') {
              console.info('Register mode is flagged on no information was passed, do nothing');
            }
            // Lets submit the comment
            const inserts = {};
            inserts[`sessions/${this.viewingThisSession?.sessionID}/${this.typeToSubmit}/${uKey}`] = this.sessionComment;
            await firebase.database().ref().update(inserts, (error) => {
              if (!error) {
                this.resetComment();
              } else {
                console.error('Problem inserting comment into firebase; ', error);
              }
            });
          }
          setTimeout(() => { this.submitting = false; this.preventionState = false; }, 500);
        }
      }
    },
    resetComment() {
      this.submitting = false;
      this.sessionComment.comment = '';
    },
    // Counter functions
    updateCounters(key) {
      this.counters.forEach((c) => {
        if (c.sessionID === key && c.sessionID !== this.selectedKey) {
          // eslint-disable-next-line no-param-reassign
          c.counter += 1;
        }
      });
    },
    resetCounter(key) {
      this.counters.forEach((c) => {
        if (c.sessionID === key && c.sessionID) {
          // eslint-disable-next-line no-param-reassign
          c.counter = 0;
        }
      });
    },
    // Scrolling functions
    handleScroll() {
      setTimeout(() => {
        const out = document.getElementById('out');
        console.log(out.scrollHeight);
        if (out.scrollHeight < 1200) {
          this.scrollToBottom = true;
          this.scrolling();
        } else {
          this.scrollToBottom = false;
          if (Math.ceil(out.scrollTop) === 0) {
            this.scrollToBottom = false;
            setTimeout(() => {
              this.scrollToBottom = true;
              this.scrolling();
            }, 20000);
          }
          const middle = Math.ceil(out.scrollHeight / 2);
          if (Math.ceil(out.scrollTop) <= middle) {
            this.scrollToBottom = false;
            setTimeout(() => {
              this.scrollToBottom = true;
              this.scrolling();
            }, 20000);
          }
          const check = Math.ceil(out.scrollHeight - out.clientHeight);
          if (Math.ceil(out.scrollTop) >= check) {
            this.scrollToBottom = true;
            this.scrolling();
          }
        }
      }, 100);
    },
    scrolling() {
      setTimeout(() => {
        const out = document.getElementById('out');
        if (!this.scrollToBottom) {
          console.log('Do not hit bottom');
        } else {
          out.scrollTop = out.scrollHeight - out.clientHeight;
        }
      }, 100);
    },
    // Helper functions
    // Check for active amount
    /**
     * Generate a unique uid per client connection
     */
    genUUID() {
      // Check for localstorage first
      const uidCheck = sessionStorage.getItem('uid');
      if (uidCheck !== undefined && uidCheck !== null) {
        this.uuid = uidCheck;
      } else {
        const uid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          // eslint-disable-next-line no-bitwise,no-mixed-operators
          const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
        this.uuid = uid;
        sessionStorage.setItem('uid', this.uuid);
      }
    },
    /**
     * Generate meta information from the event if set
     * faviconURL: This is the favicon url
     * eventName: Is the name of the event to update the browser tab
     */
    setMetaData(faviconURL, eventName) {
      document.title = eventName;
      // TODO: redo the favicon logic
    },
    /**
     * Set the event colors set on the event obj stored in the firebase side of things.
     */
    setEventStyling(data) {
      // font size
      this.fontSize = data.fontSize;
      // Color settings
      this.background = data.backgroundColor;
      this.tabFontColor = data.tabFontColor;
      this.tabBackground = data.tabBackground;
      this.tabActiveBackground = data.tabActiveBackground;
      this.tabActiveFontColor = data.tabActiveFontColor;
      this.buttonBackground = data.buttonBackground;
      this.buttonTextColor = data.buttonTextColor;
      this.buttonActiveBackgroundColor = data.buttonActiveBackgroundColor;
      this.buttonActiveFontColor = data.buttonActiveFontColor;
      this.commentFontColor = data.chatTextBackground;
      this.commentBackgroundColor = data.chatMessageBackground;
    },
    /**
     * Set the event fonts deilivered from the wordpress side of things.
     * Two params passed in: gofont, gotabfont, gomessagefont
     * gofont: Main font
     * gotabfont: font related to tab fonts
     * gomessagefont: font located in the messages section
     */
    getFonts() {
      if (this.$route.query.gofont !== undefined) {
        const fontURL = atob(this.$route.query.gofont);
        const primaryFont = atob(this.$route.query.gotabfont).replace('_', ' ');
        const secondaryFont = atob(this.$route.query.gomessagefont).replace('_', ' ');
        const fontLink = document.createElement('link');
        fontLink.rel = 'stylesheet';
        fontLink.href = fontURL;
        document.getElementsByTagName('head')[0].appendChild(fontLink);
        this.primaryFont = primaryFont;
        this.secondaryFont = secondaryFont;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  ::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    /*FireFox*/
    scrollbar-width: none !important;
    /*IE10+*/
    -ms-overflow-style: -ms-autohiding-scrollbar !important;
  }
  .alert{
    background-color: rgba(255,255,255,.7);
    padding: 20px;
    margin: 10px;

    p{
      font-size: 14px;
      font-style: italic;
      &:not(:first-child) {
        padding-top: 5px;
      }
    }
  }
  .event-frame {
    width: 100%;
    min-height: 120vh;
    height: 120vh;
    overflow: hidden;
    min-height: -webkit-fill-available;

    padding: 0;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    background: #ffffff;

    .event {
      width: 100%;
      max-width: 1400px;
      min-height: 120vh;
      height: 120vh;
      min-height: -webkit-fill-available;

      padding: 0;
      margin: 0 auto;

      position:absolute;
      top:0;
      left:0;
      right:0;
      bottom:0;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      @media screen and (max-width: 15rem) {
        background: none !important;
      }

      .tabs {
        width: 100%;
        height: 40px;
        min-height: 40px;
        max-height: 41px;
        overflow-y: scroll;

        padding: 0;
        margin: 0;

        @media screen and (max-width: 15rem) {
          max-height: 100vh;
          height: 100%;
          overflow: hidden;
          width: 35px;
          display:flex;
          flex-direction: column;
          justify-content: center;
          -webkit-writing-mode: vertical-rl;
          writing-mode: vertical-rl;
          writing-mode: tb-rl;
          text-align: left;
          margin-top: -4px;
          border: none !important;
          margin-top: 0px;
          background: transparent;
          transform: rotate(180deg);
        }

        ul {
          border-bottom: none transparent;

          @media screen and (max-width: 15rem) {
            justify-content: center !important;
            margin-left: 4px;
          }

          li {
            width: fit-content;
            border: none transparent;
            color: var(--accent-color);
            font-weight: bold;

            justify-content: space-between;
            padding: 10px 15px 10px 15px;
            align-items: center;

            display: flex;
            flex-grow: 1;

            &:not(:last-child) {
              margin-right: 3px;

              @media screen and (max-width: 15rem) {
                &:not(:last-child) {
                  border-bottom: 1px solid black !important;
                }
              }
            }

            cursor: pointer;

            @media screen and (max-width: 15rem) {
              padding: 30px 6px 30px 6px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              margin-right: 0 !important;
              flex-grow: 0 !important;
            }
            @media screen and (max-width: 5rem) {
              margin-right: 0 !important;
              justify-content: center;
            }

            .counter {
              width: 15px;
              height: 15px;
              background: white;
              border-radius: 50%;
              display: flex;
              align-items: center;
              align-content: center;
              justify-content: center;
              padding: 0;
              font-size: 0.8rem;

              @media screen and (max-width: 15rem) {
                margin-top: 10px;
                margin-right: 3px;
              }
            }
            .has-icon {
              @media screen and (max-width: 15rem) {
                transform: rotate(90deg);
              }
            }

            .hidden {
              display: none !important;
              width: 0 !important;
              height: 0 !important;
            }
          }
        }
      }
      .session {
        width: 100%;
        height: 100%;
        max-height: calc(100% - 40px);

        @media screen and (max-width: 15rem) {
          min-height: 100vh;
          max-height: 100vh;
          display: none !important;
        }

        .session-contents {
          width: 100%;
          min-height: 94vh;
          max-height: 94vh;
          overflow: hidden;

          padding: 0;
          margin: 0;

          display: flex;
          flex-direction: column;

          .comments-section {
            width: 100%;
            max-height: 77vh;
            //overflow: hidden;
            overflow: scroll;
            flex-grow: 1;
            padding: 10px;

            .pinned-message {
              width: 100%;
              height: auto;

              padding: 10px 0 10px 0;
              margin-bottom: 13px;

              border-bottom: 1px solid #333;

              display: flex;
              flex-direction: row;
              align-items: center;
              align-content: center;
              justify-content: flex-start;
              background: transparent;

              p {
                word-break: break-word;
              }
            }

            ul {
              width: 100%;
              max-height: 77vh;
              overflow: scroll;

              &::-webkit-scrollbar {
                display: none;
              }
            }

            .pinnedStyling {
              overflow: scroll;
              max-height: 64vh !important;
              padding-bottom: 10px !important;
            }

            img {
              height: auto;
              max-height: fit-content;
            }

            .comment {
              width: 100%;
              height: auto;
              margin-top: 10px;

              display: flex;
              flex-direction: column;
              align-items: flex-start;
              align-content: center;
              justify-content: flex-start;
              background: transparent;

              .comment-name {
                width: 100%;
                height: auto;

                color: hsl(0, 0%, 20%);
                font: 600 12px "Helvetica Neue", sans-serif;
                text-transform: capitalize;
                padding: 0 0 1px 0;
                margin-bottom: 2px;
                display: flex;
                align-items: center;
                align-content: center;
              }

              .comment-content {
                width: 100%;
                max-width: 99%;
                height: auto;

                font-size: .975rem;
                letter-spacing: 0.05px;
                line-height: 1.65;
                word-wrap: break-word;
                color: #333333;

                float: left;
                display: inline-block;
                position: relative;

                border-bottom: 1px solid lightgray;
                padding-bottom: 7px;
              }
            }

            @supports (-webkit-touch-callout: none) {
              min-height: 45%;
              max-height: 45%;
            }
            @media screen and (max-width: 45rem) {
              max-height: none;
            }
          }
          .border-top {
            border-top: 1px solid;
          }
          .border-bottom {
            border-bottom: 1px solid;
          }
          .required-fields {
            width: 100%;
            height: fit-content;

            padding: 10px 10px 20px 10px;

            @supports (-webkit-touch-callout: none) {
              flex-grow: 1;
            }

            @media screen and (max-width: 45rem) {
              flex-grow: 0;
            }

            textarea, .textarea {
              resize: none;

              @media screen and (max-width: 45rem) {
                width: 100%;
                min-height: 0;
                height: 50px;
                max-height: 50px;
              }
            }

            .bottom-section {
              width: 95%;
              margin-top: 10px;
            }

            .button {
              height: fit-content !important;
              color: #333;
              padding: 2px 10px 2px 10px;
              font-weight: 700;
              font-size: 1.1rem !important;
            }
          }
          .smaller {
            @supports (-webkit-touch-callout: none) {
              max-height: 60% !important;
              min-height: 40% !important;
            }
            @media screen and (max-width: 45rem) {
              min-height: 59%;
            }
          }
          .locked-message {
            width: 100%;
            height: auto;

            padding: 20px;

            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            font-weight: 600;
            font-size: 1.2rem;
          }
          // File downloads
          .fileDownload {
            width: 100%;
            height: auto;

            background: #F5F7FB;
            border: 1px solid lighten(#5F5DFF, 22);

            a {
              width: 100%;
              height: 100%;

              padding: 20px;

              display: flex;
              flex-direction: row;
              align-items: center;
              align-content: center;
              justify-content: center;

              &:hover, &:active {
                background: darken(#F5F7FB, 3);
              }
            }
          }
        }
        .session-contents-empty {
          width: 100%;
          height: 100vh;

          align-items: center;
          align-content: center;
          justify-content: center !important;
          font-weight: bold;
          font-size: 1.3rem;
        }
      }
    }
  }
  @media screen and (min-width: 46rem) {
    .emoji-section {
      max-width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  @media screen and (max-width: 45rem) {
    .emoji-section {
      width: 95%;
      margin: 0 auto;
    }
  }
</style>
